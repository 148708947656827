<template>
  <v-container fluid class="service-detail-height">
    <v-row>
      <v-col cols="6" class="py-0 pl-0">
        <v-card flat class="remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4 py-0">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16 text-uppercase"
              >Basic Information</span
            >
          </v-card-title>
          <v-card-text class="font-size-16 pre-wrap px-0 py-0">
            <table class="width-100">
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Specification
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.specification">
                    {{ detail.specification }}
                  </span>
                  <em v-else class="text-muted"> no specification </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Tags
                </td>
                <td class="font-size-18 py-1">
                  <template v-if="detail?.tags.length > 0">
                    <span v-if="detail && detail.tags">
                      <v-chip
                        v-for="(row, index) in detail.tags"
                        :key="index"
                        class="mr-2"
                        text-color="white"
                        :color="row.color"
                        small
                        >{{ row.text }}</v-chip
                      >
                    </span>
                  </template>
                  <em v-else class="text-muted"> no Tags</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" valign="top">
                  Description
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.description">{{
                    detail.description
                  }}</span>
                  <em v-else class="text-muted"> no description </em>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="pb-0 pl-0 pt-0" v-if="show_skills == 1">
        <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4 py-0">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16 text-uppercase"
              >Skills , Licence & Certs Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <v-simple-table fixed-header height="67vh">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="simple-table-th px-2">#</th>
                    <th class="simple-table-th px-2">Color</th>
                    <th class="simple-table-th px-2">Name</th>
                    <th class="simple-table-th px-2">Type</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="detail?.certificates?.length > 0">
                    <tr
                      v-for="(row, index) in detail.certificates"
                      :key="index"
                      :class="{
                        'custom-border-top': index == 0,
                        'orange_lighten-5': index % 2 == 0,
                      }"
                    >
                      <td class="simple-table-td">{{ index + 1 }}</td>
                      <td class="simple-table-td">
                        <v-chip
                          small
                          :color="row.color"
                          variant="flat"
                        ></v-chip>
                      </td>
                      <td class="simple-table-td text-capitalize">
                        {{ row.name }}
                      </td>
                      <td class="simple-table-td text-capitalize">
                        {{ row.type }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="pageLoading">
                      <tr v-for="row in 5" :key="row">
                        <td>
                          <v-skeleton-loader
                            type="text"
                            class="custom-skeleton table-rows-text"
                          ></v-skeleton-loader>
                        </td>
                        <td>
                          <v-skeleton-loader
                            type="text"
                            class="custom-skeleton table-rows-text"
                          ></v-skeleton-loader>
                        </td>
                        <td>
                          <v-skeleton-loader
                            type="text"
                            class="custom-skeleton table-rows-text"
                          ></v-skeleton-loader>
                        </td>
                        <td>
                          <v-skeleton-loader
                            type="text"
                            class="custom-skeleton table-rows-text"
                          ></v-skeleton-loader>
                        </td>
                      </tr>
                    </template>
                    <tr v-else-if="!pageLoading">
                      <td colspan="4">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no row at the moment.
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <CustomFormDetail class="px-0" module="service" :module-id="detail.id" />
    </v-row>
  </v-container>
</template>

<script>
import CustomFormDetail from "@/view/pages/custom-field-v2/CustomFormDetail.vue";
import moment from "moment-timezone";
import { GET } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      product: 0,
      panel: 0,
      panelImage: 0,
      pageLoading: false,
      dataLoading: true,
      summary: "this_week",
      show_skills: 0,
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      certificates: [],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  components: {
    CustomFormDetail,
  },
  methods: {
    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/service" })
        .then(({ data }) => {
          _this.show_skills = data.service_skills_allow;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    /* this.getSummary(); */
    this.getServiceSetting();
  },
};
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
